
export default {
  name: 'CmsMediclub3BenefitItem',

  props: {
    item: {
      type: Object,
      required: true,
    },

    grayFooter: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onItemClick () {
      if (this.item.to) {
        this.$router.push(this.item.to)
      }
    },
  },
}
